import React from "react"
import { Link } from "gatsby"

import { styled } from "@lib/theme"

export interface ILogoProps {
    box?: boolean
}

const LinkComponent = ({ box, ...props }: React.ComponentPropsWithoutRef<typeof Link> & ILogoProps) => (
    <Link {...props}>
        LST
        <br />
        FND
    </Link>
)

const LogoComponent = styled<typeof LinkComponent>(({ box, ...props }) => <LinkComponent {...props} />)`
    color: ${({ box, theme: { colors } }) => (box ? colors.white : colors.black)};
    text-decoration: none;
    position: fixed;
    top: 27px;
    right: 25px;
    z-index: ${({ box }) => (box ? 10 : 5)};
`

export const Logo = React.memo(LogoComponent)
