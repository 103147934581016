import React from "react"
import { Helmet } from "react-helmet"

import { BASE_URL } from "../../config/paths"
import image from "../assets/metaimage.png"

interface ISeoProps {
    title?: string
    description: string
    lang: string
    imageUrl: string
}

function SEO({ description, lang, title, imageUrl }: ISeoProps) {
    return (
        <Helmet title={title} titleTemplate="%s | LST&FND" defaultTitle="LST&FND" defer={false}>
            <html lang={lang} />
            <meta name="description" content={description} />
            {title && <meta property="og:title" content={`${title} | LST&FND`} />}
            <meta property="og:description" content={description} />
            <meta property="og:image" content={imageUrl} />
            <meta property="og:type" content="website" />
        </Helmet>
    )
}

SEO.defaultProps = {
    lang: "en",
    description: "A street photography collection.",
    imageUrl: BASE_URL + image,
} as ISeoProps

export default SEO
