import React from "react"
import { Box, Heading, SxStyleProp } from "rebass"

import { Logo } from "@components/Logo"

import type { Theme } from "@lib/theme"

const headerCss: SxStyleProp = (t: Theme) => ({
    color: t.colors.white,
    fontSize: "8px",
    lineHeight: 1.5,
    fontFamily: t.fonts.logoType,
    zIndex: 10,
})

const headingCss: SxStyleProp = (t: Theme) => ({
    ...headerCss(t),
    backgroundColor: "#000",
    position: "absolute",
    top: 20,
    right: 20,
    width: 36,
    height: 36,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    clip: "rect(0, auto, auto, 0)",
    transition: "all 250ms",
    ":hover": {
        opacity: 0,
    },
})

interface IHeaderProps {
    siteTitle?: string
}

const HeaderComponent = ({ siteTitle }: IHeaderProps = { siteTitle: "LST&FND" }) => (
    <Box as="header" sx={headerCss}>
        <Logo title={siteTitle} to="/" />
        <Heading as="h1" sx={headingCss}>
            <Logo title={siteTitle} to="/" box />
        </Heading>
    </Box>
)

export const Header = React.memo(HeaderComponent)
