import React from "react"
import { useTheme } from "emotion-theming"
import { Box, Flex } from "rebass"

import { Theme } from "@lib/theme"

import Icon from "../assets/icon-heart.svg"

const FooterComponent = () => {
    const theme = useTheme<Theme>()

    return (
        <Flex flexDirection="row" justifyContent="space-between" fontSize="12px" color="lightgrey">
            <Box>
                Created with <Icon width="8" height="8" fill={theme.colors.blue} /> in Frankfurt am Main.
            </Box>
            <Box>&copy; 2020 LST&FND</Box>
        </Flex>
    )
}

export const Footer = React.memo(FooterComponent)
